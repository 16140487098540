import React, { Component } from "react";
import axios from "axios";
import "../../Styles/admin.css";
import "../../Styles/dashboard.css";
import Newsidebar from "../Dashboard/Newsidebar";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import ReactPaginate from "react-paginate";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import MultiSelect from "multiselect-react-dropdown";
import { Table } from "react-bootstrap";
import listdata from "./../../Images/listdata.png";
import { ip } from "../../Api";
import { ToastContainer, toast } from "react-toastify";
import loading from "../../Images/loader.gif";
import "../../Styles/loader.scss";
import "react-toastify/dist/ReactToastify.css";
class Admin extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.state = {
      IstargetVisible: false,
      IsclaimtrackerVisible:false,
      modal: false,
      updatemodal: false,
      deletemodal: false,
      togglereset: false,
      filtermodal: false,
      dataforupdate: null,
      refreshtoken: sessionStorage.getItem("refreshtoken"),
      accesstoken: sessionStorage.getItem("accesstoken"),
      role_type: sessionStorage.getItem("role_type"),
      clientDetails: sessionStorage.getItem("clientDetails"),
      lastname: "",
      clientarray1: [],
      selectedShiftErr:"",
      id: "",
      firstname: "",
      phno: "",
      email: "",
      code: "",
      user: [],
      checked: false,
      checked_tracker: false,
      updateclaim_tracker: "",
      createmodal: false,
      updatefirstname: "",
      updatelastname: "",
      updateemail: "",
      updatephno: "",
      updatetarget: "",
      updateClientDetails: [],
      updatemanagername: "",
      updaterolename: "",
      updaterole: "",
      updatemanager: [],
      updatecode: "",
      updateid: "",
      updateusername: "",
      code: "",
      filterdata: [],
      filter_firstname: "",
      role_array: [],
      team: [],
      filterrole: "",
      filterval: 0,
      role: "",
      newpassword: "",
      confirmedpassword: "",
      filterdate: "",
      filterroleErr: "",
      filterdateErr: "",
      clientErr: "",
      roletype: "",
      offset: 0,
      currentpage: 0,
      perPage: 10,
      selectedclient: [],
      clientslist: [],
      firstnameErr: "",
      lastnameErr: "",
      roleErr: "",
      phnoErr: "",
      passwordsMatch: false,
      codeErr: "",
      emailErr: "",
      ManagerData: [],
      updateclient: "",
      updateclientid: "",
      client: sessionStorage.getItem("clientid"),
      role_type: sessionStorage.getItem("role_type"),
      password: "",
      clientvaluearray: [],
      username: "",
      seepassword: false,
      passwordErr: "",
      usernameErr: "",
      cfmpassword: "",
      cfmpasswordErr: "",
      fetching: true,
      target: "",
      targetErr: "",
      updatetarget1: "",
      updateusername: "",
      selectedShift: '',
      updatedselectedShift:'',
      updatedselectedShiftErr:"",
      isProcessing: false,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  toggle = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };
  // toogle modal for delete
  toggledelete = () => {
    this.setState({ deletemodal: !this.state.deletemodal });
  };
  //toggle modal for update
  toggleupdate = () => {
    this.setState({ updatemodal: !this.state.updatemodal });
  };
  togglereset = () => {
    this.setState({
      togglereset: !this.state.togglereset,
      passwordsMatch: false,
      confirmedpassword: "",
      newpassword: "",
    });
  };
  togglefilter = () => {
    this.setState({ filtermodal: !this.state.filtermodal });
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentpage: selectedPage,
        offset: offset,
      },
      () => {
        this.getusers();
      }
    );
  };

  handleSubmit = async () => {
    this.setState({isProcessing:true})
    const isValid = this.validate();
    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const isteamMember = this.state.role == "Team Member";
      const isAdmin = this.state.role == "Admin";
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      let array2 = []; // Define array2 as an empty array
      if (this.multiselectRef.current.state.selectedValues.length !== 0) {
        let array1 = this.multiselectRef.current.state.selectedValues.map(
          (data, index) => {
            return {
              client: data.client,
              clientId: data.clientId,
              disable: false,
            };
          }
        );
        array2.push(...array1); // Spread the array1 elements into array2
      }
      let value;
      if (isAdmin) {
        if (this.state.username) {
          value = {
            claimtracker: this.state.checked_tracker,
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            role: this.state.role,
            username: this.state.username,
            password: this.state.password,
            country_code: this.state.code,
            phone_number: this.state.phno,
            clientDetails: array2,
            shift: this.state.selectedShift,
          };
        } else {
          value = {
            claimtracker: this.state.checked_tracker,
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            role: this.state.role,
            email: this.state.email,
            country_code: this.state.code,
            phone_number: this.state.phno,
            clientDetails: array2,
            shift: this.state.selectedShift,
          };
        }
      } else if (isteamMember) {
        if (this.state.username) {
          value = {
            claimtracker: this.state.checked_tracker,
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            role: this.state.role,
            username: this.state.username,
            password: this.state.password,
            country_code: this.state.code,
            phone_number: this.state.phno,
            target: this.state.target,
            shift: this.state.selectedShift,
            clientDetails: array2,
          };
        } else {
          value = {
            claimtracker: this.state.checked_tracker,
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            role: this.state.role,
            email: this.state.email,
            country_code: this.state.code,
            phone_number: this.state.phno,
            clientDetails: array2,
            target: this.state.target,
            shift: this.state.selectedShift,
          };
        }
      } else {
        if (this.state.username) {
          value = {
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            role: this.state.role,
            username: this.state.username,
            password: this.state.password,
            country_code: this.state.code,
            phone_number: this.state.phno,
            clientDetails: array2,
          };
        } else {
          value = {
            claimtracker: this.state.checked_tracker,
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            role: this.state.role,
            email: this.state.email,
            country_code: this.state.code,
            phone_number: this.state.phno,
            clientDetails: array2,
          };
        }
      }
      await axios
        .post(`${ip}/users/create/user`, value, {
          headers: headers,
        })
        .then((res) => {
          if (res) {
            this.setState({ createmodal: !this.state.createmodal });
            toast.success("User added successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
            this.getusers();
            this.toggle();
          }
        })
        .catch((err) => {
          if (err.response) {
            this.toggle();
            this.getusers();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
    this.setState({isProcessing:false})
  };

  //getusers
  getRoles = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const res = axios
      .get(`${ip}/role/all`, { headers: headers })
      .then((res) => {
        this.setState({
          role_array: res.data.role,
        });
      })
      .catch((err) => {});
  };
  handleChangeRole = (e) => {
    const selectedRole = e.target.value;
    this.setState({ role: selectedRole });

    const { role_array } = this.state;
    const matchingRole = role_array.find(
      (role) => role.role_name === selectedRole
    );
    if (matchingRole) {
      this.setState({
        IstargetVisible: matchingRole.target,
        IsclaimtrackerVisible: matchingRole.claimTracker,
      });
    } else {
      this.setState({
        IstargetVisible: false,
        IsclaimtrackerVisible: false,
      });
    }
  };
  handleChangeRole1 = (e) => {
    const selectedRole = e.target.value;
    this.setState({ updaterole: selectedRole });
  };

  updateVisibility = (selectedRole) => {
    const { role_array } = this.state;
    const matchingRole = role_array.find(
      (role) => role.role_name === selectedRole
    );
    if (matchingRole) {
      this.setState({
        IstargetVisible: matchingRole.target,
        IsclaimtrackerVisible: matchingRole.claimTracker,
      });
    } else {
      this.setState({
        IstargetVisible: false,
        IsclaimtrackerVisible: false,
      });
    }
  };

  // fuction for prepopulate the particular data
  putrequeststeam = async (dataid) => {
    this.setState({isProcessing:true});
    const isValid = this.updatevalidate();

    if (isValid) {
      this.setState({ createmodal: !this.state.createmodal });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.accesstoken}`,
      };
      let teamdata;
      let array2 = []; // Define array2 as an empty array
      if (this.multiselectRef.current.state.selectedValues.length !== 0) {
        let array1 = this.multiselectRef.current.state.selectedValues.map(
          (data, index) => {
            return {
              client: data.client,
              clientId: data.clientId,
              disable: false,
            };
          }
        );
        array2.push(...array1); // Spread the array1 elements into array2
      }
      if (this.state.updateusername === "") {
        teamdata = {
          claimtracker: this.state.updateclaim_tracker,
          first_name: this.state.updatefirstname,
          last_name: this.state.updatelastname,
          role: this.state.updaterole,
          email: this.state.updateemail,
          country_code: this.state.updatecode,
          phone_number: this.state.updatephno,
          clientDetails: array2,
          target: this.state.updatetarget1,
          shift: this.state.updatedselectedShift,
        };
      } else {
        teamdata = {
          claimtracker: this.state.updateclaim_tracker,
          first_name: this.state.updatefirstname,
          last_name: this.state.updatelastname,
          clientDetails: array2,
          role: this.state.updaterole,
          username: this.state.updateusername,
          country_code: this.state.updatecode,
          phone_number: this.state.updatephno,
          target: this.state.updatetarget1,
          shift: this.state.updatedselectedShift,
        };
      }
      // eslint-disable-next-line
      await axios
        .put(`${ip}/users/${dataid}`, teamdata, {
          headers: headers,
        })
        .then((res) => {
          if (res) {
            this.toggleupdate();
            this.getusers();
            this.setState({ createmodal: !this.state.createmodal });
            toast.info("User details updated", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.toggleupdate();
            this.getusers();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
    this.setState({isProcessing:false});
  };

  deleterequests = async (DataID) => {
    //api call to delete requests
    this.setState({isProcessing:true})
    this.setState({ createmodal: !this.state.createmodal });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    const req = axios
      .delete(`${ip}/users/${DataID}`, {
        headers: headers,
      })
      .then((req) => {
        if (req) {
          const requests = this.state.user.filter(
            (data) => data._id !== DataID
          );
          this.setState({ requests }, () => {});
          this.toggledelete();
          this.getusers();
          this.setState({ createmodal: !this.state.createmodal });
          toast.error("User deleted successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.toggledelete();
          this.getpayer();
          this.setState({ createmodal: !this.state.createmodal });
          if (err.response.data.statusCode === 500) {
            toast.error("Server Error", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else if (err.response.data.statusCode === 401) {
            toast.error("Please reload and try again", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          } else {
            toast.error("An unknown error occured", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
          }
        }
      });
      this.setState({isProcessing:false})
  };

  filterdata = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    if (this.state.filter_firstname === "" || this.state.filterrole === "") {
      toast.error("It is mandatory to fill all the fields!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({ filtermodal: !this.state.filtermodal });
    } else {
      this.setState({ createmodal: !this.state.createmodal });
      axios
        .get(
          `${ip}/users/filter?first_name=${this.state.filter_firstname}&role=${this.state.filterrole}`,
          { headers: headers }
        )
        .then((res) => {
          if (res) {
            this.togglefilter();
            const slice = res.data.user.slice(
              this.state.offset,
              this.state.offset + this.state.perPage
            );
            this.setState({
              filterval: " ",
              pageCount: Math.ceil(res.data.user.length / this.state.perPage),
              filterdata: slice,
            });
            this.setState({ createmodal: !this.state.createmodal });

            toast.success("Filter applied!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            this.togglefilter();
            this.setState({ createmodal: !this.state.createmodal });
            if (err.response.data.statusCode === 500) {
              toast.error("Server Error", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else if (err.response.data.statusCode === 401) {
              toast.error("Please reload and try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            } else {
              toast.error("An unknown error occured", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            }
          }
        });
    }
    // }
  };

  //to renew accesstoken
  renewaccesstoken = async () => {
    const renewheaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.refreshtoken}`,
    };
    // eslint-disable-next-line
    await axios
      .get(`${ip}/auth/renewAccessToken`, {
        headers: renewheaders,
      })
      .then((renew) => {
        sessionStorage.setItem("accesstoken", renew.data.accessToken);
      })
      .catch((err) => {});
  };
  //getusers
  getusers = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    axios
      .get(`${ip}/users/all?clientId=${this.state.client}&pageno=${this.state.currentpage}`, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          pageCount: Math.ceil(res.data.count / this.state.perPage),
          user: res.data.user,
          fetching: false,
        });
      })
      .catch((err) => {});
  };

  validate = () => {
    let hasErr = true;
    let firstnameErr = "";
    let lastnameErr = "";
    let roleErr = "";
    let phnoErr = "";
    let emailErr = "";
    let codeErr = "";
    let clientErr = "";
    let passwordErr = "";
    let cfmpasswordErr = "";
    let usernameErr = "";
    let targetErr = "";
    let selectedShiftErr = "";
    if (this.state.firstname === "") {
      firstnameErr = "This field is required";
      this.setState({ firstnameErr });
      hasErr = false;
    }
    if (this.state.lastname === "") {
      lastnameErr = "This field is required";
      this.setState({ lastnameErr });
      hasErr = false;
    }
    if (this.state.code === "") {
      codeErr = "This field is required";
      this.setState({ codeErr });
      hasErr = false;
    } else if (!/^[+]\d{2}$/.test(this.state.code)) {
      codeErr = "Invalid Number";
      this.setState({ codeErr });
      hasErr = false;
    }
    if (this.state.role === "") {
      roleErr = "This field is required";
      this.setState({ roleErr });
      hasErr = false;
    }
    if (this.state.selectedShift === "") {
      selectedShiftErr = "This field is required";
      this.setState({ selectedShiftErr });
      hasErr = false;
    }
    if (this.multiselectRef.current.state.selectedValues.length === 0) {
      clientErr = "This field is required";
      this.setState({ clientErr });
      hasErr = false;
    }
    if (this.state.checked === true && this.state.username === "") {
      usernameErr = "This field is required";
      this.setState({ usernameErr });
      hasErr = false;
    }
    if (this.state.checked === true && this.state.password === "") {
      passwordErr = "This field is required";
      this.setState({ passwordErr });
      hasErr = false;
    }
    if (this.state.checked === true && this.state.cfmpassword === "") {
      cfmpasswordErr = "This field is required";
      this.setState({ cfmpasswordErr });
      hasErr = false;
    } else if (
      this.state.checked === true &&
      this.state.password !== this.state.cfmpassword
    ) {
      cfmpasswordErr = "Password and confirm password should be same";
      this.setState({ cfmpasswordErr });
      hasErr = false;
    }
    if (this.state.phno === "") {
      phnoErr = "This field is required";
      this.setState({ phnoErr });
      hasErr = false;
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
        this.state.phno
      )
    ) {
      phnoErr = "Invalid phone number";
      this.setState({ phnoErr });
      hasErr = false;
    }
    if (
      this.state.username === "" &&
      this.state.password === "" &&
      this.state.email === ""
    ) {
      emailErr = "This field is required";
      this.setState({ emailErr });
      hasErr = false;
    } else if (
      // eslint-disable-next-line
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
        this.state.email
      ) &&
      this.state.username === "" &&
      this.state.password === ""
    ) {
      emailErr = "Invalid email address";
      this.setState({ emailErr });
      hasErr = false;
    }


    return hasErr;
  };

  filtervalidate = () => {
    let hasErr = "true";
    let filterdateErr = "";
    let filterroleErr = "";
    if (this.state.filterdate === "") {
      filterdateErr = "This field is required";
      this.setState({ filterdateErr });
      hasErr = false;
    }
    if (this.state.filterrole === "") {
      filterroleErr = "This field is required";
      this.setState({ filterroleErr });
      hasErr = false;
    }
    return hasErr;
  };
  updatevalidate = () => {
    let hasErr = true;
    let firstnameErr = "";
    let lastnameErr = "";
    let phnoErr = "";
    let emailErr = "";
    let usernameErr = "";
    let updatedselectedShiftErr="";
    if (this.state.updatefirstname === "") {
      firstnameErr = "This field is required";
      this.setState({ firstnameErr });
      hasErr = false;
    }

    if (this.state.updatelastname === "") {
      lastnameErr = "This field is required";
      this.setState({ lastnameErr });
      hasErr = false;
    }
    if (this.state.updatedselectedShift === "") {
      updatedselectedShiftErr = "This field is required";
      this.setState({ updatedselectedShiftErr });
      hasErr = false;
    }

    if (this.state.updateusername === "") {
      usernameErr = "This field is required";
      this.setState({ usernameErr });
      hasErr = false;
    }
    if (this.state.updatephno === "") {
      phnoErr = "This field is required";
      this.setState({ phnoErr });
      hasErr = false;
    } else if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
        this.state.updatephno
      )
    ) {
      phnoErr = "Invalid phone number";
      this.setState({ phnoErr });
      hasErr = false;
    }
    if (this.state.updateusername === "" && this.state.updateemail === "") {
      emailErr = "This field is required";
      this.setState({ emailErr });
      hasErr = false;
    } else if (
      // eslint-disable-next-line
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
        this.state.updateemail
      ) &&
      this.state.updateusername === ""
    ) {
      //var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      emailErr = "Invalid email address";
      this.setState({ emailErr });
      hasErr = false;
    }

    return hasErr;
  };
  getmanager = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    axios
      .get(`${ip}/users/all`, { headers: headers })
      .then((res) => {
        const userrolearray = res.data.user.filter(
          (item) => item.role === "Team Manager"
        );
        this.setState({ ManagerData: userrolearray });
      })
      .catch((err) => {});
  };
  passValidation = () => {
    if (this.state.newpassword !== this.state.confirmedpassword) {
      this.setState({ passwordsMatch: true });
      return false;
    } else {
      this.setState({ passwordsMatch: false });
      return true;
    }
  };
  handleReset = async (data) => {
    this.setState({isProcessing:true});
    const isValid = this.passValidation();
    if (isValid) {
      let value;
      value = {
        username: data,
        newpassword: this.state.newpassword,
        confirmpassword: this.state.confirmedpassword,
        user: false,
      };
      // eslint-disable-next-line
      await axios
        .post(`${ip}/auth/resetpassword`, value)
        .then((res) => {
          alert("Password changed successfully");
          this.togglereset();
          this.setState({
            newpassword: "",
            confirmedpassword: "",
          });
        })
        .catch((err) => {});
    }
    this.setState({isProcessing:false})
  };
  reset = () => {
    this.setState({
      firstname: "",
      lastname: "",
      phno: "",
      email: "",
      code: "",
      selectedmanager: [],
      selectedrole: [],
      filterdate: "",
      firstnameErr: "",
      lastnameErr: "",
      roleErr: "",
      phnoErr: "",
      emailErr: "",
      updaterole: "",
      selectedfilterrole: [],
      filterdateErr: "",
      filterroleErr: "",
      codeErr: "",
      clientErr: "",
      claimtracker: "",
      username: "",
      password: "",
      usernameErr: "",
      passwordErr: "",
      cfmpassword: "",
      cfmpasswordErr: "",
      target: "",
      targetErr: "",
    });
  };
  getclientadmin = async () => {
    let array1 = JSON.parse(this.state.clientDetails);
    let clients = array1.map((item) => {
      return {
        client: item.client,
        clientId: item.clientId,
        disable: item.disable,
        displayName: `${item.client} (${item.clientId})`,
      };
    });
    this.setState({
      clientarray1: clients,
    });
  };

  onSelect = (selectedList, selectedItem) => {
    const selectedClients = selectedList.map((item) => item.client);
    this.setState({ clientErr: "" });
    console.log("Selected clients:", selectedClients);
  };

  onRemove = (selectedList, removedItem) => {
    const selectedClients = selectedList.map((item) => item.client);
    console.log("Selected clients:", selectedClients);
  };
  handleChangeShift = (event) => {
    this.setState({ selectedShift: event.target.value, selectedShiftErr:"" });
  };
  handleChangeUpdatedShift = (event) => {
    this.setState({ updatedselectedShift: event.target.value, updatedselectedShiftErr:"" });
  };
  getclients = async () => {
    //api call to get all users
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.state.accesstoken}`,
    };
    // eslint-disable-next-line
    axios
      .get(`${ip}/clientonboard/all`, { headers: headers })
      .then((res) => { 
        this.setState({
          clientslist: res.data.clientonboard,
        });
      })
      .catch((err) => {});
  };

  componentDidMount() {
    this.getRoles();
    this.updateVisibility(this.state.updaterole);
    this.getusers();
    this.getclients();
    this.getclientadmin();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.updaterole !== this.state.updaterole) {
      this.updateVisibility(this.state.updaterole);
    }
  }
  render() {
    return (
      <div>
        <Newsidebar name="User onBoard" />
        <div className="align-row" style={{ overflow: "hidden" }}>
          <div className="align-column">
            <br></br>
            <div className="align-row items">
              <ToastContainer />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginRight: "auto" }}>
                  <h5 className="userlist-head">User List</h5>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn"
                      style={{
                        backgroundColor: "#041c3c",
                        color: "#fff",
                        width: "fit-content",
                        fontSize: "15px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                      onClick={this.togglefilter}
                    >
                      Filter
                    </button>
                    <button
                      type="button"
                      className="btn"
                      style={{
                        backgroundColor: "#7DD657",
                        color: "#fff",
                        width: "fit-content",
                        fontSize: "15px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                      }}
                      onClick={this.toggle}
                    >
                      Create New User
                    </button>
                  </div>
                </div>
              </div>

              <hr className="hr-user" />
            </div>
            {this.state.filterval === 0 ? (
              <div>
                {this.state.fetching ? (
                  // <img src={loading} />
                  <div>
                    <p
                      style={{
                        fontSize: "23px",
                        marginTop: "10px",
                        color: "#000",
                      }}
                    >
                      <span>Loading...</span>
                      <span>Please Wait...</span>
                    </p>
                    <div className="boxes body__loader dribbble">
                      <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                ) : this.state.user.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "25px",
                    }}
                  >
                    <img src={listdata} alt="" width="50%" height="50%" />
                  </div>
                ) : (
                  <div>
                    <Table bordered className="StandardTable">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email ID / Username</th>
                          <th style={{ whiteSpace: "nowrap" }}>Phone Number</th>
                          <th style={{ whiteSpace: "nowrap" }}>Created Date</th>
                          <th>Role</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.user &&
                          this.state.user.map((data, index) => (
                            <tr key={data._id}>
                              <td>{data._id}</td>
                              <td component="th" scope="row">
                                {data.first_name}
                              </td>
                              <td>{data.last_name}</td>
                              <td>{data.email ? data.email : data.username}</td>
                              <td>{data.phone_number}</td>
                              <td>{data.createdAt.substring(0, 10)}</td>
                              <td>{data.role}</td>
                              <td>
                              <button
                              className="Edit"
                              onClick={() => {
                                const formattedClientDetails = data.clientDetails.map(clientDetail => ({
                                  client: clientDetail.client,
                                  clientId: clientDetail.clientId,
                                  disable: clientDetail.disable,
                                  displayName: `${clientDetail.client} (${clientDetail.clientId})`
                                }));
                            
                                this.setState(
                                  { dataforupdate: data },
                                  () => {
                                    this.setState({
                                      updatefirstname: data.first_name,
                                      updatelastname: data.last_name,
                                      updaterole: data.role,
                                      updateclient: data.client,
                                      updateclaim_tracker: data.claimtracker,
                                      updateclientid: data.clientid,
                                      updateusername: data.username,
                                      updateemail: data.email,
                                      updateClientDetails: formattedClientDetails,
                                      updatephno: data.phone_number,
                                      updatecode: data.country_code,
                                      updateid: data._id,
                                      updatetarget1: data.target,
                                      updatedselectedShift: data.shift,
                                    }, () => {
                                      this.toggleupdate();
                                    });
                                  }
                                );
                              }}
                            >
                              Edit
                            </button>
                            
                                &nbsp;
                                <button
                                  className="Edit"
                                  onClick={() => {
                                    this.setState(
                                      { dataforupdate: data },
                                      () => {
                                        this.setState(
                                          {
                                            updatefirstname: data.first_name,
                                            updatelastname: data.last_name,
                                            updaterole: data.role,
                                            updateclient: data.client,
                                            updateclientid: data.clientid,
                                            updateclaim_tracker:
                                              data.claimtracker,
                                            updateusername: data.username,
                                            updateemail: data.email,
                                            updatephno: data.phone_number,
                                            updatecode: data.country_code,
                                            updateid: data._id,
                                            updatetarget1: data.target,
                                          },
                                          () => this.togglereset()
                                        );
                                      }
                                    );
                                  }}
                                >
                                  Reset Password
                                </button>
                                &nbsp;
                                <button
                                  className="Delete"
                                  onClick={() => {
                                    this.setState({ id: data._id }, () => {
                                      this.toggledelete();
                                    });
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                            // ))}
                          ))}
                      </tbody>
                    </Table>
                    <ReactPaginate
                      previousLabel={<IoIosArrowBack />}
                      nextLabel={<IoIosArrowForward />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div>
                {this.state.filterdata.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3 align="center">No data found</h3>
                  </div>
                ) : (
                  <div>
                    <Table bordered className="StandardTable">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email_ID</th>
                          <th>Phone Number</th>
                          <th>Created Date</th>
                          <th>Role</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.filterdata &&
                          this.state.filterdata.map((data, index) => (
                            <tr key={data._id}>
                              <td component="th" scope="row">
                                {data.first_name}
                              </td>
                              <td>{data.last_name}</td>
                              <td>{data.email}</td>
                              <td>{data.phone_number}</td>
                              <td>{data.createdAt.substring(0, 10)}</td>
                              <td>{data.role}</td>
                              <td>
                                <button
                                className="Edit"
                                onClick={() => {
                                  const formattedClientDetails = data.clientDetails.map(clientDetail => ({
                                    client: clientDetail.client,
                                    clientId: clientDetail.clientId,
                                    disable: clientDetail.disable,
                                    displayName: `${clientDetail.client} (${clientDetail.clientId})`
                                  }));
                              
                                  this.setState(
                                    { dataforupdate: data },
                                    () => {
                                      this.setState({
                                        updatefirstname: data.first_name,
                                        updatelastname: data.last_name,
                                        updaterole: data.role,
                                        updateclient: data.client,
                                        updateclaim_tracker: data.claimtracker,
                                        updateclientid: data.clientid,
                                        updateusername: data.username,
                                        updateemail: data.email,
                                        updateClientDetails: formattedClientDetails,
                                        updatephno: data.phone_number,
                                        updatecode: data.country_code,
                                        updateid: data._id,
                                        updatetarget1: data.target
                                      }, () => {
                                        this.handleChangeRole1();
                                        this.toggleupdate();
                                      });
                                    }
                                  );
                                }}
                                >
                                  Edit
                                </button>
                                &nbsp;
                                <button
                                  className="Delete"
                                  onClick={() => {
                                    this.setState({ id: data._id }, () => {
                                      this.toggledelete();
                                    });
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                            // ))}
                          ))}
                      </tbody>
                    </Table>
                    <div style={{ marginLeft: "3%", marginTop: "20px" }}>
                      <ReactPaginate
                        previousLabel={<IoIosArrowBack />}
                        nextLabel={<IoIosArrowForward />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {this.state.modal && (
              <Modal
                isOpen={this.state.modal}
                onHide={this.toggle}
                onExit={this.reset}
                centered
                size="lg"
              >
                <ModalHeader toggle={this.toggle}>Create New User</ModalHeader>
                <ModalBody
                  style={{
                    "max-height": "calc(100vh - 210px)",
                    "overflow-y": "auto",
                  }}
                >
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label className="required">First Name</Label>
                          <Input
                            type="text"
                            required
                            value={this.state.firstname}
                            onChange={(e) =>
                              this.setState({
                                firstname: e.target.value,
                                firstnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label className="required">Last Name</Label>
                          <Input
                            type="text"
                            required
                            value={this.state.lastname}
                            onChange={(e) =>
                              this.setState({
                                lastname: e.target.value,
                                lastnameErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.lastnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <FormGroup>
                        {this.state.checked === false && (
                          <div>
                            <Label className="required">Email Address</Label>
                            <Input
                              type="email"
                              required
                              value={this.state.email}
                              onChange={(e) =>
                                this.setState({
                                  email: e.target.value,
                                  emailErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.emailErr}
                            </div>
                          </div>
                        )}
                      </FormGroup>
                    </Row>
                    <br />
                    <Row>
                      <li
                        style={{
                          color: "#041c3c",
                          fontSize: "12px",
                          fontStyle: "italic",
                          fontWeight: "bold",
                          display: "inline-block",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={this.state.checked}
                          onChange={() =>
                            this.setState({ checked: !this.state.checked })
                          }
                        />
                        <label>&nbsp;Set username and password manually</label>
                      </li>
                    </Row>
                    <br />
                    {this.state.checked === true && (
                      <div>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label className="required">Username</Label>
                              <Input
                                type="text"
                                value={this.state.username}
                                onChange={(e) =>
                                  this.setState({
                                    username: e.target.value,
                                    usernameErr: "",
                                  })
                                }
                              />
                              <div style={{ fontSize: 16, color: "red" }}>
                                {this.state.usernameErr}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label className="required">Password</Label>
                              <Input
                                type="password"
                                value={this.state.password}
                                onChange={(e) =>
                                  this.setState({
                                    password: e.target.value,
                                    passwordErr: "",
                                  })
                                }
                              />
                              <div style={{ fontSize: 16, color: "red" }}>
                                {this.state.passwordErr}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label className="required">
                                Confirm Password
                              </Label>
                              <Input
                                type={
                                  this.state.seepassword ? "text" : "password"
                                }
                                value={this.state.cfmpassword}
                                onChange={(e) => {
                                  this.setState({
                                    cfmpassword: e.target.value,
                                  });
                                  if (this.state.password === e.target.value) {
                                    this.setState({ cfmpasswordErr: "" });
                                  }
                                }}
                              />
                              {this.state.seepassword ? (
                                <AiOutlineEyeInvisible
                                  className="userconfirm-password"
                                  onClick={() =>
                                    this.setState({
                                      seepassword: !this.state.seepassword,
                                    })
                                  }
                                />
                              ) : (
                                <AiOutlineEye
                                  className="userconfirm-password"
                                  onClick={() =>
                                    this.setState({
                                      seepassword: !this.state.seepassword,
                                    })
                                  }
                                />
                              )}
                              <div style={{ fontSize: 16, color: "red" }}>
                                {this.state.cfmpasswordErr}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    )}
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label className="required">Mobile Number</Label>
                          <Row>
                            <Col md="2">
                              <FormGroup>
                                <Input
                                  type="alphanumeric"
                                  required
                                  value={this.state.code}
                                  onChange={(e) =>
                                    this.setState({
                                      code: e.target.value,
                                      phnoErr: "",
                                      codeErr: "",
                                    })
                                  }
                                />
                                <div style={{ fontSize: 16, color: "red" }}>
                                  {this.state.codeErr}
                                </div>
                              </FormGroup>
                            </Col>

                            <Col md="10">
                              <FormGroup>
                                <Input
                                  type="number"
                                  required
                                  value={this.state.phno}
                                  onChange={(e) =>
                                    this.setState({
                                      phno: e.target.value,
                                      phnoErr: "",
                                    })
                                  }
                                />
                                <div style={{ fontSize: 16, color: "red" }}>
                                  {this.state.phnoErr}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />

                    {this.state.role_type === "SuperAdmin" && (
                      <div>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label className="required">Client Details</Label>
                              <MultiSelect
                                options={this.state.clientslist}
                                displayValue="client"
                                showCheckbox={true}
                                ref={this.multiselectRef}
                                onSelect={(event) => {
                                  this.setState({ clientErr: "" });
                                }}
                                // onSearch={true}
                                emptyRecordMsg="No clients found"
                              />
                            </FormGroup>
                          </Col>    
                 
                        </Row>
                        <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.clientErr}
                      </div>
                      </div>
                    )}
                    {this.state.role_type === "Admin" && (
                      <div>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label className="required">Client Details</Label>
                              <MultiSelect
                                options={this.state.clientarray1}
                                displayValue="displayName" // Display formatted text
                                showCheckbox={true}
                                ref={this.multiselectRef}
                                emptyRecordMsg="No clients found"
                                onSelect={this.onSelect} // Handle selection
                                onRemove={this.onRemove} // Handle removal
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.clientErr}
                        </div>
                      </div>
                    )}
                    <br />
                    <Row>
                      <Col style={{ width: "47%" }}>
                        <label className="required">Role</label>
                        <Input
                          type="select"
                          className="form-control form-select"
                          value={this.state.role}
                          onChange={this.handleChangeRole}
                        >
                          <option value="" hidden>
                            Select Role...
                          </option>
                          {this.state.role_array.map((role) => (
                            <option key={role._id} value={role.role_name}>
                              {role.role_name}
                            </option>
                          ))}
                        </Input>
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.roleErr}
                        </div>
                      </Col>
                      <Col>
                      <label className="required">Shift List</label>
                      <Input type="select" id="shift-select" value={this.state.selectedShift} 
                      className="form-control form-select"
                      onChange={this.handleChangeShift}>
                      <option value="" hidden>Select Shift</option>
                      <option value="Dayshift">Day Shift</option>
                      <option value="Nightshift">Night Shift</option>
                    </Input>
                    <div style={{ fontSize: 16, color: "red" }}>
                    {this.state.selectedShiftErr}
                  </div>
                      </Col>
                    </Row>
                    <br />
                    {this.state.IstargetVisible && (
                      <div>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>Target</Label>
                              <Input
                                type="number"
                                value={this.state.target}
                                onChange={(e) =>
                                  this.setState({ target: e.target.value })
                                }
                              />
                              <div style={{ fontSize: 16, color: "red" }}>
                                {this.state.targetErr}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <br />
                      </div>
                    )}
                    <br />
                    {this.state.IsclaimtrackerVisible && (
                      <Row>
                      <Col>
                        <Input
                          type="checkbox"
                          checked={this.state.checked_tracker}
                          onChange={() =>
                            this.setState({
                              checked_tracker: !this.state.checked_tracker,
                            })
                          }
                        />
                        &nbsp;Claim Tracker
                      </Col>
                    </Row>
                    )}
                    <br />
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button
                    style={{
                      border: "1px solid grey",
                      color: "black",
                      backgroundColor: "#fff",
                    }}
                    onClick={() => {
                      this.toggle();
                      this.reset();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ backgroundColor: "black", color: "white" }}
                    onClick={this.handleSubmit}
                  >
                    {this.state.isProcessing ? "Processing..." : "Create"}
                  </Button>
                </ModalFooter>
              </Modal>
            )}
            {this.state.updatemodal && this.state.dataforupdate && (
              <Modal
                style={{ width: "30%" }}
                isOpen={this.state.updatemodal}
                onHide={this.toggleupdate}
                onExit={this.reset}
                centered
              >
                <ModalHeader toggle={this.toggleupdate}>
                  Update User
                </ModalHeader>
                <ModalBody
                  style={{
                    "max-height": "calc(100vh - 210px)",
                    "overflow-y": "auto",
                    "overflow-x": "hidden",
                  }}
                >
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>First Name</Label>
                          <Input
                            type="text"
                            required
                            value={this.state.updatefirstname}
                            onChange={(e) =>
                              this.setState({
                                updatefirstname: e.target.value,
                                // updateErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.firstnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Last Name</Label>
                          <Input
                            type="text"
                            // disabled
                            value={this.state.updatelastname}
                            onChange={(e) =>
                              this.setState({
                                updatelastname: e.target.value,
                                // emailErr: "",
                              })
                            }
                          />
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.lastnameErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      {this.state.updateemail ? (
                        <Col>
                          <FormGroup>
                            <Label>Email Address</Label>
                            <Input
                              type="text"
                              required
                              value={this.state.updateemail}
                              onChange={(e) =>
                                this.setState({
                                  updateemail: e.target.value,
                                  emailErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.emailErr}
                            </div>
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col>
                          <FormGroup>
                            <Label>Username</Label>
                            <Input
                              type="text"
                              required
                              value={this.state.updateusername}
                              onChange={(e) =>
                                this.setState({
                                  updateusername: e.target.value,
                                  usernameErr: "",
                                })
                              }
                            />
                            <div style={{ fontSize: 16, color: "red" }}>
                              {this.state.usernameErr}
                            </div>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Mobile Number</Label>
                          <Row>
                            <Col md="3">
                              <Input
                                type="alphanumeric"
                                required
                                value={this.state.updatecode}
                                onChange={(e) =>
                                  this.setState({ updatecode: e.target.value })
                                }
                              />
                            </Col>
                            <Col>
                              <Input
                                type="number"
                                required
                                value={this.state.updatephno}
                                onChange={(e) =>
                                  this.setState({
                                    updatephno: e.target.value,
                                    phnoErr: "",
                                  })
                                }
                              />
                            </Col>
                          </Row>
                          <div style={{ fontSize: 16, color: "red" }}>
                            {this.state.phnoErr}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    {this.state.role_type === "Admin" && (
                      <div>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label className="required">Client Details</Label>
                              <MultiSelect
                                options={this.state.clientarray1}
                                displayValue="displayName"
                                showCheckbox={true}
                                ref={this.multiselectRef}
                                selectedValues={this.state.updateClientDetails}
                                emptyRecordMsg="No clients found"
                                onSelect={this.onSelect}
                                onRemove={this.onRemove}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.clientErr}
                      </div>
                        <br />
                      </div>
                    )}

                    {this.state.role_type === "SuperAdmin" && (
                      <div>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label className="required">Client Details</Label>
                              <MultiSelect
                                options={this.state.clientslist}
                                displayValue="client"
                                showCheckbox={true}
                                ref={this.multiselectRef}
                                selectedValues={this.state.updateClientDetails}
                                emptyRecordMsg="No clients found"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <div style={{ fontSize: 16, color: "red" }}>
                        {this.state.clientErr}
                      </div>
                      </div>
                    )}
                    <br />
                    <Row>
                      <Col style={{ width: "47%" }}>
                        <label>Role</label>
                        <Input
                          type="select"
                          value={this.state.updaterole}
                          onChange={this.handleChangeRole1}
                        >
                          <option value="" hidden>
                            Select Role...
                          </option>
                          {this.state.role_array.map((role) => (
                            <option key={role._id} value={role.role_name}>
                              {role.role_name}
                            </option>
                          ))}
                        </Input>
                      </Col>
                      <Col>
                      <label>Shift List</label>
                      <Input type="select" id="shift-select" defaultValue={this.state.updatedselectedShift} 
                      className="form-control form-select required"
                      onChange={this.handleChangeUpdatedShift}>
                      <option value="" hidden>Select Shift</option>
                      <option value="Dayshift">Day Shift</option>
                      <option value="Nightshift">Night Shift</option>
                    </Input>
                      </Col>
                      <div style={{ fontSize: 16, color: "red" }}>
                      {this.state.updatedselectedShiftErr}
                    </div>
                    </Row>
                    <br />
                    {this.state.IstargetVisible && (
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Target</Label>
                            <Input
                              type="number"
                              value={this.state.updatetarget1}
                              onChange={(e) =>
                                this.setState({ updatetarget1: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <br />
                    {this.state.IsclaimtrackerVisible && (
                      <Row>
                      <Col>
                        <Input
                          type="checkbox"
                          checked={this.state.updateclaim_tracker}
                          onChange={() =>
                            this.setState({
                              updateclaim_tracker:
                                !this.state.updateclaim_tracker,
                            })
                          }
                        />
                        &nbsp;Claim Tracker
                      </Col>
                    </Row>
                    )}
                    <br />
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button
                    style={{
                      border: "1px solid grey",
                      color: "black",
                      backgroundColor: "#fff",
                    }}
                    onClick={() => {
                      this.toggleupdate();
                      this.reset();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ backgroundColor: "black", color: "white" }}
                    onClick={() => {
                      this.putrequeststeam(this.state.updateid);
                    }}
                  >
                    {this.state.isProcessing ? "Processing..." : "Update"}
                  </Button>
                </ModalFooter>
              </Modal>
            )}
            {this.state.togglereset && (
              <Modal
                style={{ width: "30%" }}
                isOpen={this.state.togglereset}
                onHide={this.togglereset}
                onExit={this.reset}
                centered
              >
                <ModalHeader toggle={this.togglereset}>
                  Reset Password
                </ModalHeader>
                <ModalBody>
                  {this.state.passwordsMatch === true ? (
                    <p style={{ color: "red", fontSize: "15px" }}>
                      Passwords does not match.
                    </p>
                  ) : null}
                  <Form>
                    <Row>
                      <FormGroup>
                        <Label>New Password</Label>
                        <Input
                          type="text"
                          required
                          value={this.state.newpassword}
                          onChange={(e) =>
                            this.setState({
                              newpassword: e.target.value,
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.firstnameErr}
                        </div>
                      </FormGroup>
                    </Row>
                    <br />
                    <Row>
                      <FormGroup>
                        <Label>Confirm Password</Label>
                        <Input
                          type="text"
                          value={this.state.confirmedpassword}
                          onChange={(e) =>
                            this.setState({
                              confirmedpassword: e.target.value,
                            })
                          }
                        />
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.lastnameErr}
                        </div>
                      </FormGroup>
                    </Row>
                    <br />
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button
                    style={{
                      border: "1px solid grey",
                      color: "black",
                      backgroundColor: "#fff",
                    }}
                    onClick={() => {
                      this.togglereset();
                      this.reset();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ backgroundColor: "black", color: "white" }}
                    onClick={() => {
                      this.handleReset(this.state.updateusername);
                    }}
                  >
                    {this.state.isProcessing ? "Processing..." : "Reset"}
                  </Button>
                </ModalFooter>
              </Modal>
            )}
            {this.state.deletemodal && (
              <Modal
                isOpen={this.state.deletemodal}
                onHide={this.toggledelete}
                centered
              >
                <ModalHeader toggle={this.toggledelete}>
                  Delete User
                </ModalHeader>
                <ModalBody
                  style={{
                    "max-height": "calc(100vh - 210px)",
                    "overflow-y": "auto",
                  }}
                >
                  <Row style={{ margin: "0px" }}>
                    Do you want to delete this?
                  </Row>

                  <br />
                </ModalBody>
                <ModalFooter>
                  <Button
                    style={{
                      border: "1px solid grey",
                      color: "black",
                      backgroundColor: "#fff",
                    }}
                    onClick={this.toggledelete}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ backgroundColor: "black", color: "white" }}
                    onClick={() => {
                      this.deleterequests(this.state.id);
                    }}
                  >
                    {this.state.isProcessing ? "Processing..." : "Delete"}
                  </Button>
                </ModalFooter>
              </Modal>
            )}
            {this.state.filtermodal && (
              <Modal
                style={{ width: "50%" }}
                isOpen={this.state.filtermodal}
                onHide={this.togglefilter}
                onExit={this.reset}
                centered
              >
                <ModalHeader toggle={this.togglefilter}>Filter</ModalHeader>
                <ModalBody>
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>First Name</Label>
                          <Input
                            type="text"
                            required
                            value={this.state.filter_firstname}
                            placeholder="Enter first name"
                            onChange={(e) =>
                              this.setState({
                                filter_firstname: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col style={{ width: "47%" }}>
                        <label>Role</label>
                        {this.state.role_type === "SuperAdmin" ? (
                          <Input
                            type="select"
                            className="form-control form-select"
                            value={this.state.filterrole}
                            onChange={(e) =>
                              this.setState({ filterrole: e.target.value })
                            }
                          >
                            <option value="" hidden>
                              Select Role...
                            </option>
                            <option value="Admin">Admin</option>
                            <option value="SuperAdmin">SuperAdmin</option>
                          </Input>
                        ) : (
                          <Input
                            type="text"
                            className="form-control form-select"
                            value={this.state.filterrole}
                          ></Input>
                        )}
                        <div style={{ fontSize: 16, color: "red" }}>
                          {this.state.filterroleErr}
                        </div>
                      </Col>
                    </Row>
                    <br />
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button
                    style={{
                      border: "1px solid grey",
                      color: "black",
                      backgroundColor: "#fff",
                    }}
                    onClick={() => {
                      this.togglefilter();
                      this.reset();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ backgroundColor: "black", color: "white" }}
                    onClick={this.filterdata}
                  >
                    Apply
                  </Button>
                </ModalFooter>
              </Modal>
            )}
            <Modal isOpen={this.state.createmodal} centered>
              <ModalBody>
                <img
                  src={loading}
                  style={{ width: "200px", height: "200px", marginLeft: "30%" }}
                />
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
export default Admin;
