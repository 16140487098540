import React, { useState } from "react";
import "./Styles/login.css";
import { Button } from "reactstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import obologo from "./Images/Imglogo.png";
import "./Styles/login.scss";
import { ip } from "./Api";
import { useSocket } from "./Components/Context/socketContext";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [clientId, setClientId] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [err, setErr] = useState(0);
  const [msg, setMsg] = useState("");
  const [access, setAccess] = useState(false);
  const [refreshToken, setRefreshToken] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [roleType, setRoleType] = useState("");
  const [username, setUsername] = useState("");
  const [checked, setChecked] = useState(false);
  const [loginCount, setLoginCount] = useState("");
  const [clientList, setClientList] = useState([]);
  const [clientIdErr, setClientIdErr] = useState("");
  const [firstPage, setFirstPage] = useState("");
  const socket = useSocket();
  const togglePassword = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const clientValidate = () => {
    let hasErr = "true";
    if (!clientId) {
      setClientIdErr("This field is required");
      hasErr = false;
    } else {
      setClientIdErr("");
    }
    return hasErr;
  };

  const handleSubmit = async () => {
    const Valid = clientValidate();
    if (Valid) {
      const value = {
        clientId: parseInt(clientId),
        ...(checked ? { email } : { username }),
        password,
      };
      try {
        const res = await axios.post(`${ip}/auth/login`, value);
        setMsg(res.data.message);

        const responseData = res.data;
        const advancedMDData = responseData.toggle.ehrDetails.find(
          (entry) => entry.client === "AdvancedMD"
        );

        const officeKeyValue = advancedMDData ? advancedMDData.officekey : null;

        if (res.data.accessToken) {
          sessionStorage.setItem("officekey", officeKeyValue);
          sessionStorage.setItem("api-sync", res.data.apisync);
          localStorage.setItem("isAuthenticated", true);
          sessionStorage.setItem("accesstoken", res.data.accessToken);
          sessionStorage.setItem("refreshtoken", res.data.refreshToken);
          sessionStorage.setItem("firstname", res.data.user_details.first_name);
          sessionStorage.setItem(
            "Default_pagination",
            res.data.user_details.paginationCount
          );
          sessionStorage.setItem(
            "with_notes",
            res.data.toggle.combainedReportNotes
          );
          sessionStorage.setItem("object_id", res.data.toggle._id);
          sessionStorage.setItem("lastname", res.data.user_details.last_name);
          sessionStorage.setItem("role_type", res.data.role);
          sessionStorage.setItem("user_id", res.data.user_details._id);
          sessionStorage.setItem("totalusercount", res.data.totalusercount);
          sessionStorage.setItem(
            "claim_tracker",
            res.data.user_details.claimtracker
          );
          sessionStorage.setItem("pre-toggle", res.data.toggle.closedcpt);
          sessionStorage.setItem("email_id", res.data.user_details.email);
          sessionStorage.setItem("phone", res.data.user_details.phone_number);
          sessionStorage.setItem("clientid", res.data.clientId);
          sessionStorage.setItem("clientname", res.data.client);
          sessionStorage.setItem("apiusername", res.data.user_details.username);
          sessionStorage.setItem(
            "role_access",
            JSON.stringify(res.data.roleaccess)
          );
          sessionStorage.setItem(
            "clientDetails",
            JSON.stringify(res.data.user_details.clientDetails)
          );

          setAccessToken(res.data.accessToken);
          setRefreshToken(res.data.refreshToken);
          setRoleType(res.data.role);
          setLoginCount(res.data.user_details.logincount);
          setAccess(true);
          const firstPageAccess = getFirstTrueAccessPage(res.data.roleaccess);
          setFirstPage(firstPageAccess);
          // Emit registerClient
          if (socket) {
            socket.emit("registerClient", {
              clientId: parseInt(res.data.clientId),
            });
            console.log("Registered client:", {
              clientId: parseInt(res.data.clientId),
            });
          }
          const clientArray = res.data.user_details.clientDetails.map(
            (data) => data.clientId
          );
          setClientList(clientArray);
        }
      } catch (error) {
        setErr(1);
        setAccess(false);
      }
    }
  };

  const getFirstTrueAccessPage = (roleAccess) => {
    const accessMap = {
      management: {
        inventory: "/management-inventory-analysis",
        outcome: "/management-outcomes",
        actions: "/management-actions",
        industryStandards: "/management-industry-standards",
        adminUserperformanceDashboard: "/management-userperformance-dashboard",
        trendsAndPatterns: "/management-trends-patterns",
        reports: "/generate-reports",
        claimQuickCheck: "/single-claim-check",
      },
      claim: {
        keyIndicators: "/allocation-buckets",
        denialManagement: "/denial-management",
        claimManagement: "/claim-management",
        automationTracker: "/automation-track",
      },
      dataProcessing: {
        databaseSync: "/database-sync",
        apiSync: "/api-sync",
        dataUploadFile: "/data",
        dataDeleteFile: "/dataDeleting",
        dataConversionOcr: "/dataconversionocr",
        dataCleaning: "/datacleaning",
        dataMigration: "/datamigration",
      },
      workqueue: {
        workqueue: "/actions",
      },
      allocation: {
        allocated: "/allocate/allocated",
        unallocated: "/allocate/unallocated",
        rejectionAndDenials: "/allocate/rejection",
      },
      configure: {
        configure: "/configure/configure-list",
      },
      userManagement: {
        useronboard: "/admin/useronboard",
        role: "/roles",
      },
      eligibility: {
        eligibilityDataupload: "/eligibility/Dataupload",
        eligibilityTracker: "/eligibility/Outcomedata",
        eligibilityQuickcheck: "/eligibility/Check",
        eligibilityUploadAuthorization: "/eligibility/Auth",
        eligibilityPriorauthTracker: "/eligibility/PriorAuth",
      },
      userPerformanceDashboard: {
        userPerformanceDashboard: "/performances",
      },
      completed: {
        completed: "/user-completed-page",
      },
      inprogress: {
        inprogress: "/user-inprogress-page",
      },
      denial: {
        denial: "/user-denial-page",
      },
      needToCall: {
        needToCall: "/user-needtocall-page",
      },
      pending: {
        pending: "/user-nottouch-page",
      },
      userWorkqueue: {
        userWorkqueue: "/actions",
      },
      userRejectionAndDenials: {
        userRejectionAndDenials: "/action-rejection-denials",
      },
      claimTracker: {
        claimtrackerOpenclaims: "/open-claims",
        claimtrackerClosedclaims: "/closed-claims",
      },
    };

    const orderedAccessKeys = [
      {
        key: "management",
        subKeys: [
          "inventory",
          "outcome",
          "actions",
          "industryStandards",
          "adminUserperformanceDashboard",
          "trendsAndPatterns",
          "reports",
          "claimQuickCheck",
        ],
      },
      {
        key: "claim",
        subKeys: [
          "keyIndicators",
          "denialManagement",
          "claimManagement",
          "automationTracker",
        ],
      },
      {
        key: "dataProcessing",
        subKeys: [
          "databaseSync",
          "apiSync",
          "dataUploadFile",
          "dataDeleteFile",
          "dataConversionOcr",
          "dataCleaning",
          "dataMigration",
        ],
      },
      { key: "workqueue", subKeys: ["workqueue"] },
      {
        key: "allocation",
        subKeys: ["allocated", "unallocated", "rejectionAndDenials"],
      },
      { key: "configure", subKeys: ["configure"] },
      { key: "userManagement", subKeys: ["useronboard", "role"] },
      {
        key: "eligibility",
        subKeys: [
          "eligibilityDataupload",
          "eligibilityTracker",
          "eligibilityQuickcheck",
          "eligibilityUploadAuthorization",
          "eligibilityPriorauthTracker",
        ],
      },
      {
        key: "userPerformanceDashboard",
        subKeys: ["userPerformanceDashboard"],
      },
      { key: "completed", subKeys: ["completed"] },
      { key: "inprogress", subKeys: ["inprogress"] },
      { key: "denial", subKeys: ["denial"] },
      { key: "needToCall", subKeys: ["needToCall"] },
      { key: "pending", subKeys: ["pending"] },
      { key: "userWorkqueue", subKeys: ["userWorkqueue"] },
      { key: "userRejectionAndDenials", subKeys: ["userRejectionAndDenials"] },
      {
        key: "claimTracker",
        subKeys: ["claimtrackerOpenclaims", "claimtrackerClosedclaims"],
      },
    ];

    const findFirstAccessPage = (accessObj, accessMap, keys) => {
      for (const item of keys) {
        const key = item.key;
        const subKeys = item.subKeys;

        for (const subKey of subKeys) {
          if (
            accessObj[subKey] === true &&
            accessMap[key] &&
            accessMap[key][subKey]
          ) {
            return accessMap[key][subKey];
          }
        }
      }
      return null;
    };

    return findFirstAccessPage(roleAccess, accessMap, orderedAccessKeys);
  };

  if (loginCount === 0) {
    return <Redirect to="/change_password" />;
  } else if (access) {
    return <Redirect to={firstPage} />;
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="nav-title">
        <img
          style={{
            width: "160px",
            left: "0px",
            marginTop: "10px",
            position: "fixed",
          }}
          src={obologo}
          alt="obo"
        />
      </div>
      <br />
      <br />
      <br />
      <div className="global-container">
        <div className="cardlogin login-form">
          <div className="card-bodylogin">
            <h4
              className="card-titlelogin text-center"
              style={{
                fontFamily: "Josefin Sans",
                fontWeight: 500,
                color: "#041c3c",
              }}
            >
              RevShield A.I
            </h4>
            {msg === "Access Granted" ? (
              <div>
                <label align="center" style={{ color: "green", fontSize: 13 }}>
                  {msg}
                </label>
              </div>
            ) : (
              <div>
                <label align="center" style={{ color: "red", fontSize: 13 }}>
                  {msg}
                </label>
              </div>
            )}
            <div
              align="right"
              style={{ fontSize: "13px", fontStyle: "italic" }}
            >
              <input
                type="checkbox"
                checked={checked}
                onChange={() => setChecked(!checked)}
                style={{ fontSize: "13px" }}
              />
              &nbsp;Sign in with email
            </div>
            <div className="card-textlogin">
              <div className="form-group">
                <div className="form__input-group">
                  <input
                    className="form__input"
                    type="text"
                    required
                    value={clientId}
                    onChange={(e) => setClientId(e.target.value)}
                  />
                  <label
                    className="form__input-label required"
                    style={{ fontFamily: "Josefin Sans", fontWeight: 500 }}
                  >
                    CLIENT ID
                  </label>
                  <div style={{ fontSize: 13, color: "red" }}>
                    {clientIdErr}
                  </div>
                </div>
                {!checked ? (
                  <div className="form__input-group">
                    <input
                      className="form__input"
                      type="text"
                      required
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <label
                      className="form__input-label"
                      style={{ fontFamily: "Josefin Sans", fontWeight: 500 }}
                    >
                      USERNAME
                    </label>
                  </div>
                ) : (
                  <div className="form__input-group">
                    <input
                      className="form__input"
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label
                      className="form__input-label"
                      style={{ fontFamily: "Josefin Sans", fontWeight: 500 }}
                    >
                      EMAIL ID
                    </label>
                  </div>
                )}
                <div className="form__input-group">
                  <input
                    className="form__input"
                    type={isPasswordVisible ? "text" : "password"}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label
                    className="form__input-label"
                    style={{ fontFamily: "Josefin Sans", fontWeight: "500" }}
                  >
                    PASSWORD
                    {isPasswordVisible ? (
                      <AiFillEyeInvisible onClick={togglePassword} />
                    ) : (
                      <AiFillEye onClick={togglePassword} />
                    )}
                  </label>
                </div>
              </div>

              <Button
                style={{ width: "100%", backgroundColor: "#041c3c" }}
                onClick={handleSubmit}
              >
                SIGN IN
              </Button>
              <Link to="/forgotpassword" style={{ float: "right" }}>
                Forgot password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
